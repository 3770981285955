@font-face {
  font-family: 'Poly';
  font-style: normal;
  font-weight: normal;
  src: local('Poly'), url('Poly-Regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poly Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poly Italic'), url('Poly-Italic.woff') format('woff');
  }
  
.App {
  text-align: center;
}

@font-face {
  font-family: 'Charcoal';
  src: local('Charcoal'), url(./charcoal.ttf) format('truetype');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.div1 {
  width: 20px;
  height: 50px;
  float: left;
  animation-name: example;
  animation-duration: 4s;
  margin-right:5px;
  animation-iteration-count: infinite;
  animation-delay: 0.1s;
  animation-timing-function: linear;
}

.div2 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right:5px;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
  animation-timing-function: linear;
}

.div3 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right:5px;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  animation-timing-function: linear;
}

.div4 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right:5px;
  animation-iteration-count: infinite;
  animation-delay: 1.5s;
  animation-timing-function: linear;
}

.div5 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right:5px;
  animation-iteration-count: infinite;
  animation-delay: 2s;
  animation-timing-function: linear;
}

.div6 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right:5px;
  animation-iteration-count: infinite;
  animation-delay: 2.5s;
  animation-timing-function: linear;
}

.div7 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right:5px;
  animation-iteration-count: infinite;
  animation-delay: 3s;
  animation-timing-function: linear;
}

.div8 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right:5px;
  animation-iteration-count: infinite;
  animation-delay: 3.5s;
  animation-timing-function: linear;
}

.div9 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right:5px;
  animation-iteration-count: infinite;
  animation-delay: 4s;
  animation-timing-function: linear;
}

@keyframes example {
  from {background-color: green;}
  to {background-color: green;}
}


